export const settingsReducer = (state, action) => {
  switch (action.type) {
    case "SET_CURSOR":
      return {
        ...state,
        cursor: action.payload,
      }
    case "SET_GENERAL_FORM_DISPLAY":
      return {
        ...state,
        generalForm: {
          display: action.payload,
        },
      }
    case "SET_NAVIGATION_COLOR":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          color: action.payload,
        },
      }
    case "SET_NAVIGATION_BACKGROUND_COLOR":
      return {
        ...state,
        navigation: {
          ...state.navigation,
          backgroundColor: action.payload,
        },
      }
    case "SET_MOBILE_MENU":
      return {
        ...state,
        mobileMenu: {
          open: action.payload,
        },
      }
    case "SET_PERSON_DETAIL_DISPLAY":
      return {
        ...state,
        personDetail: {
          ...state.personDetail,
          display: action.payload,
        },
      }
    case "SET_PERSON_DETAIL_MEMBER":
      return {
        ...state,
        personDetail: {
          ...state.display,
          member: action.payload,
        },
      }
    default:
      return state
  }
}
